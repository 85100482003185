import React from "react";
import ContentBlock from "../content-block";
import NederlandIctLogo from '../../images/logo/nederland_ict_logo.jpg';

const TermsIntroContent = () => (
  <ContentBlock title="Terms & Conditions">
    <div className="col-md-12">
          <img alt="Nederland ICT logo" className="d-none d-md-block float-right" width="300" src={NederlandIctLogo} />
      <p>
          CuriousBits is providing services through the Terms and Conditions
          provided by{" "}
        <a
            href="https://www.nederlandict.nl/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nederland ICT
          </a>
          .<br />
          Many IT service providers in the Netherlands are using those terms as
          they provide a healthy balance between the needs of the supplier and
          the client.
        </p>
    <p>Below you can find a copy of the terms in Dutch. An English version can be provided on request.</p>

    </div>
    </ContentBlock>
);

export default TermsIntroContent;
