import React from "react";
import PdfViewer from '../pdfviewer';
import ContentBlock from '../content-block'
import NederlandIctTerms from '../../files/Nederland_ICT_Voorwaarden_2014 _NL.pdf';

const TermsViewerContent = () => (
  <ContentBlock>
    <div className="col-md-10" style={{marginTop: "-4rem"}}>
      <PdfViewer pdfBlob={NederlandIctTerms} height="45vh" />
    </div>
  </ContentBlock>
)

export default TermsViewerContent
