import React from "react";
import SEO from '../components/seo';
import Layout from '../components/layout';
import TermsIntroContent from '../components/termspage/introduction-content';
import TermsViewerContent from '../components/termspage/termsviewer-content';

const TermsPage = () => (
  <Layout>
    <SEO title="Terms & Conditions" keywords={['CuriousBits', 'Therry van Neerven', 'Nederland ICT', 'Terms & Conditions', 'Algemene Voorwaarden']} />
    <TermsIntroContent/>
    <TermsViewerContent/>
  </Layout>
);

export default TermsPage;
