import React, { Component } from "react";
import PropTypes from "prop-types";
import PDFJS from "pdfjs-dist";

// based on https://github.com/blake-simpson/gatsby-library/blob/667eda3c1f1d4b55da0008c0f35a34bebfa525bf/src/templates/view-book.js
if (typeof window !== 'undefined') {
  window.PDFJS = PDFJS;
  window.PDFJS.GlobalWorkerOptions.workerSrc = '/static/pdf.worker.js';
}

class PdfViewer extends Component {
  async componentDidMount() {
    const { pdfBlob } = this.props;
    this.pdf = await PDFJS.getDocument(pdfBlob);
    this.setState({ numPages: this.pdf.numPages });
    this.renderPage();
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate() {
    this.renderPage();
  }

  constructor(props) {
    super(props);

    this.state = {
      numPages: Infinity,
      page: 1,
      fullscreen: false
    };

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  handleKeyDown(e) {
    if (e.keyCode === 37) {
      this.prevPage();
    } else if (e.keyCode === 39) {
      this.nextPage();
    }
  }

  nextPage() {
    const nextPage = (this.state.page + 1);

    if (nextPage < 1) {
      return;
    }

    this.setState({
      page: nextPage
    });
  }

  prevPage() {
    const nextPage = (this.state.page - 1);

    if (this.state.page >= this.state.numPages) {
      return;
    }

    this.setState({
      page: nextPage
    });
  }

  navigate(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const halfPoint = Math.floor(bounds.width / 2);

    if (x < halfPoint) {
      this.prevPage();
    } else {
      this.nextPage();
    }
  }

  render() {
    const { containerId } = this.props;

    return (
      <>
        <div className="row d-flex justify-content-center">
          <canvas id={containerId} onClick={this.navigate} />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="btn-group" role="group">
            <button
              className="btn btn-outline-dark"
              disabled={this.state.page <= 1}
              onClick={this.prevPage}
            >
              Previous Page
            </button>
            <button
              className="btn btn-outline-dark"
              disabled={this.state.page >= this.state.numPages}
              onClick={this.nextPage}
            >
              Next Page
            </button>
          </div>
        </div>
      </>
    );
  }

  renderPage() {
    const { containerId } = this.props;

    this.pdf.getPage(this.state.page).then(page => {
      const scale = 1.5;
      const viewport = page.getViewport(scale);

      // Prepare canvas using PDF page dimensions
      const canvas = document.getElementById(containerId);
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: context,
        viewport: viewport
      };

      page.render(renderContext);
    });
  }
}

PdfViewer.propTypes = {
  pdfBlob: PropTypes.string.isRequired,
  containerId: PropTypes.string
};

PdfViewer.defaultProps = {
  containerId: "pdf-viewer"
};

export default PdfViewer;
